import { HttpLogQo } from '@/models/logs/HttpLogModel';
import { LogModelQo } from '@/models/logs/LogModel';
import HttpUtil from '@/utils/HttpUtil';

export const LogPage = (params?: LogModelQo) => {
  var url = '/river-log/log/server/query/page';
  return HttpUtil.post(url, params);
}

export const HttpLogPage = (params?: HttpLogQo) => {
  var url = '/river-log/log/http/query/page';
  return HttpUtil.post(url, params);
}

export const HttpLogDelete = (params?: HttpLogQo) => {
  var url = '/river-log/log/http/delete';
  return HttpUtil.post(url, params);
}